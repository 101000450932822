table {
    background: white;
    width: 100%;
    border-spacing: 0;
    * {
        font-family: Mulish;
    }
    th {
        font-weight: 700;
        font-size: 12px;
        padding: 0.3rem 0.7rem;
        border: none !important;
        text-align: left;
    }

    td.no-wrap {
        white-space: nowrap;
    }
    tbody {
        td {
            color: #0D2218;
            padding: 0.3rem;
        }
        tr:nth-child(odd) {
            background: #F7F7F9;
        }
        td.success {
            background-color: rgba(106, 227, 141, 0.2);
        }
        td.danger {
            background-color:  rgba(235, 87, 87, 0.2);
        }
        tr:hover {
            background-color: #FFEFE0;
        }
    }

}