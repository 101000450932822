$MAIN-COLOR: #ff9800;
$MAIN-BG-COLOR: #ff9800;

html {
  background-color: #E7E9E8;
}

.ql-toolbar {
  display: none !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  padding: 0;
  margin: 0;
}
.main-color {
  color: $MAIN-COLOR;
}

.main-bg {
  background-color: $MAIN-BG-COLOR;
}

.main-button {
  &:disabled {
    background-color: gray;
  }

  background-color: $MAIN-COLOR;

  &:hover,
  &:active,
  &:focus {
    background-color: $MAIN-COLOR  !important;
  }

  color: white;
  transition: all 200ms;

  &:hover {
    // transform: scale(1.1);
    color: white;
  }
}

button:focus {
  outline: none !important;
}

.navbar {
  background: #eeeeee;
  box-shadow: 0px 1px 5px #a5a5a5;


  .navbar-nav>a {
    color: #ff9800 !important;
    font-weight: bold;
  }
}



.welcome-message {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 20px;
}

.login-form {
  max-width: 500px;
  margin: auto;
  padding: 30px 50px;
  box-shadow: 5px 5px 8px rgba(197, 197, 197, 0.596);
  ;
  background-color: rgba(226, 226, 226, 0.596);

}



.boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1.5rem;
}



.home-tile {
  display: block;
  min-height: 250px;
  padding: 20px 30px;
  background-color: $MAIN-BG-COLOR;
  border-radius: 10px;
  color: white;
  background: no-repeat url(../images/bg-small1.jpg);
  background-size: cover;
  box-shadow: 3px 3px 5px rgb(66, 66, 66);

  transition: all ease-in-out 300ms;

  .texts {
    background-color: rgba(94, 94, 94, 0.555);
    margin: auto;

    .title,
    .desc {
      color: white;
    }
  }

  &:hover {
    transform: scale(1.05);
  }
}




.form-field-error {
  font-size: 0.8rem;
  color: rgb(255, 49, 49);
}

.pointer {
  cursor: pointer;
}

.keyword-tag {
  // background-color: $MAIN-COLOR;
  background-color: white;
  color: black;
  margin: 5px;
  padding: 3px 7px;
  border-radius: 24px;
  &:hover {
    border: 1px solid rgba(81, 72, 251, 0.4);
  }
  display: inline-flex;

  &> :nth-child(1) {
    flex-grow: 1;
  }
}

.icon-btn {
  cursor: pointer;
  color: $MAIN-COLOR;
}

.orange-bg {
  background-color: rgba(253, 125, 0, 0.1);
}


.pages {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 10px;

  .page {
    padding: 10px;
    border: 1px solid rgb(206, 206, 206);
    border-radius: 10px;
    box-shadow: 2px 2px rgb(209, 209, 209);
    background-color: #eeeeee;
  }
}

.create-page-modal-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  &>div {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px 15px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    cursor: pointer;
  }
}

.modal {
  top: 10%;
}

@import './components/table';
@import './components/typography';